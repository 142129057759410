<template>
	<div>
		<b-card no-body>
			<!------ entries section ------>
			<div class="m-2">
				<b-row>
					<b-col cols="12" md="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<!-- server excel button -->
						<excel-download-btn
							v-if="serverExcelExportUrl"
							class="mr-1"
							:url="serverExcelExportUrl"
							:file-name="excelFileName"
						/>

						<!-- local excel button -->
						<loading-btn
							v-else-if="excelFileName"
							class="mr-1"
							variant="primary"
							:loading="exportToExcelLoading"
							@click="exportExcel"
						>
							Export Excel
						</loading-btn>

						<label>Entries</label>
						<v-select
							v-model="perPage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block ml-50 mr-1"
						/>

						<slot name="action"></slot>
					</b-col>
				</b-row>
			</div>

			<!------ table section ------>
			<section class="position-relative table-responsive pb-2" :class="{ 'dir-right': rtl }">
				<table :id="uniqTableID" class=" table b-table table-bordered">
					<thead>
						<slot name="thead" />
					</thead>
					<tbody>
						<tr v-for="item in currentPageItems" :key="item.id">
							<slot :item="item" name="tbody" />
						</tr>
					</tbody>
				</table>
			</section>

			<!------ pagination section ------>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">
							Showing
							{{ dataMeta.from }} to {{ dataMeta.to }}
							of
							{{ dataMeta.of }} entries
						</span>
					</b-col>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalItems"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import vSelect from 'vue-select';
import { BCard, BRow, BCol, BPagination } from 'bootstrap-vue';
import * as xlsx from 'xlsx';
import LoadingBtn from '@/components/LoadingBtn.vue';
import ExcelDownloadBtn from '@/components/ExcelDownloadBtn.vue';

const generateUniqTableID = () => `id-${Math.round(Math.random() * 1e8)}-${new Date().getTime()}`;

export default {
	name: 'TableWithPagination',

	components: { BCard, BRow, BCol, BPagination, vSelect, LoadingBtn, ExcelDownloadBtn },

	inheritAttrs: false,

	props: {
		items: { required: true, type: Array },
		rtl: Boolean,
		excelFileName: { type: String, default: null },
		serverExcelExportUrl: { type: String }, // server or local
	},

	data() {
		return {
			currentPage: 1,
			perPage: 25,

			perPageOptions: [10, 25, 50, 100],
			exportToExcelLoading: false,
			uniqTableID: generateUniqTableID(),
		};
	},

	computed: {
		totalItems() {
			return this.items.length;
		},

		isLastPage() {
			return Math.ceil(this.totalItems / this.perPage) === this.currentPage;
		},

		localItemsCount() {
			if (this.isLastPage) {
				return Math.abs(this.perPage * (this.currentPage - 1) - this.totalItems);
			}

			return this.perPage;
		},

		currentPageItems() {
			return this.items.slice(this.perPage * (this.currentPage - 1), this.perPage * this.currentPage);
		},

		dataMeta() {
			return {
				from: this.perPage * (this.currentPage - 1) + (this.localItemsCount ? 1 : 0),
				to: this.perPage * (this.currentPage - 1) + this.localItemsCount,
				of: this.totalItems,
			};
		},
	},

	methods: {
		async exportExcel() {
			this.exportToExcelLoading = true;
			const perPageTemp = this.perPage;
			this.perPage = this.totalItems;

			setTimeout(() => {
				const el = document.getElementById(this.uniqTableID);
				const sheet = xlsx.utils.table_to_book(el, { sheet: 'Sheet JS' });

				xlsx.writeFile(sheet, `${this.excelFileName}.xlsx`);

				this.perPage = perPageTemp;
				this.exportToExcelLoading = false;
			}, 500);
		},
	},
};
</script>

<style lang="scss" scoped>
tr > th {
	background-color: #f3f2f7;
	border-bottom: 2px solid #ebe9f1;
}

.sum {
	background-color: #f7f7f7;
}

.dir-right {
	direction: rtl;
}
</style>
