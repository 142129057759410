<template>
	<div>
		<!-- Filters -->
		<data-filters
			:city-filter.sync="filters.city"
			:sector-filter.sync="filters.sector"
			:doner-filter.sync="filters.donor"
			@apply="loadData"
		/>

		<b-card>
			<b-row>
				<b-col md="3">
					<doughnut-chart
						:key="key"
						:height="200"
						:chart-data="chartData.doughnutChart(chart.genderData)"
						title="Beneficiaries by Gender"
					/>
				</b-col>
				<b-col cols="6">
					<bar-chart
						:key="key"
						:height="200"
						:chart-data="chartData.barChart(chart.donerData.males, chart.donerData.females, doners)"
						title="Beneficiaries by Doner"
					/>
				</b-col>
				<b-col md="3">
					<doughnut-chart
						:key="key"
						:height="200"
						:chart-data="chartData.doughnutChart(chart.consultations)"
						title="Consultations by Gender"
					/>
				</b-col>
			</b-row>
		</b-card>

		<table-with-pagination :items="items" rtl excel-file-name="gender">
			<template #thead>
				<tr>
					<th>
						<p dir="rtl">المشروع</p>
					</th>
					<th colspan="2">
						<p dir="rtl">عدد الاستشاريين&nbsp;</p>
					</th>
					<th colspan="2">
						<p dir="rtl">عدد المستفيدين</p>
					</th>
					<th colspan="2">
						<p dir="rtl">عدد المشاورات المجتمعية&nbsp;</p>
					</th>
					<th colspan="2">
						<p dir="rtl">
							عدد الحاضرين المشاورات المجتمعية&nbsp;
						</p>
					</th>
					<th colspan="2">
						<p dir="rtl">عدد الجان التي تم تشكيلها</p>
					</th>
					<th colspan="2">
						<p dir="rtl">
							عدد النازحين المشاركين في كأعضاء في لجنة المستفيدين&nbsp;
						</p>
					</th>
					<th colspan="2">
						<p dir="rtl">
							عدد المعاقين المشاركين كأعضاء&nbsp; في لجان المستفيدين&nbsp;
						</p>
					</th>
					<th colspan="2">
						<p dir="rtl">
							عدد ذوى الاعاقات الخاصة المشاركين في المشاورات
						</p>
					</th>
				</tr>
				<tr>
					<th></th>
					<th>
						<p dir="rtl">ذكور&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">انثي&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">ذكر&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">انثي</p>
					</th>
					<th>
						<p dir="rtl">ذكور&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">انثي&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">ذكور&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">انثي&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">ذكور&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">انثي&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">ذكور&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">انثي&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">ذكور&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">انثي&nbsp;</p>
					</th>
					<th>
						<p dir="rtl">ذكر</p>
					</th>
					<th>انثي&nbsp;</th>
				</tr>
			</template>
			<template #tbody="{ item }">
				<td>{{ item.project_id }}</td>
				<td>{{ item.consultants_m }}</td>
				<td>{{ item.consultants_f }}</td>
				<td>{{ item.beneficiaries_m }}</td>
				<td>{{ item.beneficiaries_f }}</td>
				<td>{{ item.discussions_m }}</td>
				<td>{{ item.discussions_f }}</td>
				<td>{{ item.discussions_count_m }}</td>
				<td>{{ item.discussions_count_f }}</td>
				<td>{{ item.committee_count_m }}</td>
				<td>{{ item.committee_count_f }}</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
				<td>0</td>
			</template>
		</table-with-pagination>
	</div>
</template>

<script>
import api from '@/utils/api';
import { BCard, BRow, BCol } from 'bootstrap-vue';
import TableWithPagination from '@/components/TableWithPagination.vue';

import BarChart from '@/components/charts/BarChart';
import DoughnutChart from '@/components/charts/DoughnutChart';

import { mapGetters } from 'vuex';
import DataFilters from '@/components/DataFilters.vue';
import chartData from '../chartData';

export default {
	components: {
		DataFilters,
		BCard,
		BRow,
		BCol,
		TableWithPagination,
		BarChart,
		DoughnutChart,
	},

	data: () => ({
		filters: {
			donor: '',
			sector: '',
			city: '',
		},

		key: 0,

		items: [],
		data: [],

		chartData,
		chart: {
			genderData: [0, 0],
			donerData: {
				males: [],
				females: [],
			},
			consultations: [0, 0],
		},
	}),

	computed: {
		...mapGetters('attributes', ['getDonors']),

		doners() {
			return this.getDonors.map((d) => d.id);
		},
	},

	created() {
		this.loadData();
	},

	methods: {
		async loadData() {
			const params = new URLSearchParams(this.getFilters());

			const { data } = await api.call({
				path: `/m&e/gender?${params}`,
				method: 'GET',
			});

			this.data = data;
			this.items = data;

			this.calcChartData();
		},

		calcChartData() {
			this.chart.genderData = [
				this.items.reduce((prev, cur) => prev + cur.beneficiaries_m, 0),
				this.items.reduce((prev, cur) => prev + cur.beneficiaries_f, 0),
			];

			this.chart.consultations = [
				this.items.reduce((prev, cur) => prev + cur.discussions_m, 0),
				this.items.reduce((prev, cur) => prev + cur.discussions_f, 0),
			];

			this.chart.donerData.males = [
				...this.doners.map((d) => {
					const count = this.items.filter((i) => i.doner === d).reduce((prev, cur) => prev + cur.beneficiaries_m, 0);

					return count;
				}),
			];

			this.chart.donerData.females = [
				...this.doners.map((d) => {
					const count = this.items.filter((i) => i.doner === d).reduce((prev, cur) => prev + cur.beneficiaries_f, 0);

					return count;
				}),
			];

			this.key++;
		},

		getFilters() {
			return {
				donor: this.filters.donor || '',
				sector: this.filters.sector || '',
				city: this.filters.city || '',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
.table-responsive {
	margin-bottom: 0;
}
</style>
