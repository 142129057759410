<template>
	<b-card no-body>
		<b-card-header class="pb-50">
			<h5>Filters</h5>
		</b-card-header>
		<b-card-body>
			<b-row>
				<b-col cols="12" md="4" class="mb-md-0 mb-2">
					<label>Donor</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="donerFilter"
						:options="getDonors"
						:reduce="(val) => val.id"
						@input="(val) => $emit('update:donerFilter', val)"
					/>
				</b-col>
				<b-col cols="12" md="4" class="mb-md-0 mb-2">
					<label>Sector</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="sectorFilter"
						:options="getSectors"
						:reduce="(val) => val.id"
						@input="(val) => $emit('update:sectorFilter', val)"
					/>
				</b-col>
				<b-col cols="12" md="4" class="mb-md-0 mb-2">
					<label>City</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="cityFilter"
						:options="getCities"
						:reduce="(val) => val.id"
						@input="(val) => $emit('update:cityFilter', val)"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div class="d-flex justify-content-end mt-2">
						<b-button variant="primary" @click="apply">Apply</b-button>
					</div>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BButton, BRow, BCol } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		BButton,
		vSelect,
	},
	props: {
		cityFilter: {
			type: [String, null],
			default: null,
		},
		sectorFilter: {
			type: [String, null],
			default: null,
		},
		donerFilter: {
			type: [String, null],
			default: null,
		},
	},

	data: () => ({}),

	computed: {
		...mapGetters('attributes', ['getCities', 'getDonors', 'getSectors']),
	},

	methods: {
		apply() {
			this.$emit('apply');
		},
	},
};
</script>

<style lang="scss"></style>
