import { $themeColors } from '@themeConfig';

// colors
const chartColors = {
	primaryColorShade: '#836AF9',
	yellowColor: '#ffe800',
	successColorShade: '#28dac6',
	warningColorShade: '#ffe802',
	warningLightColor: '#FDAC34',
	infoColorShade: '#299AFF',
	greyColor: '#4F5D70',
	blueColor: '#2c9aff',
	blueLightColor: '#84D0FF',
	greyLightColor: '#EDF1F4',
	tooltipShadow: 'rgba(0, 0, 0, 0.25)',
	lineChartPrimary: '#666ee8',
	lineChartDanger: '#ff4961',
	labelColor: '#6e6b7b',
	grid_line_color: 'rgba(200, 200, 200, 0.2)'
};

export default {
	barChart(males = [], females = [], labels = []) {
		let maxY = Math.max(Math.max(...males), Math.max(...females));
		const stepY = parseInt(maxY / 5, 10);
		maxY += stepY;

		return {
			data: {
				labels,
				datasets: [
					{
						label: 'Female',
						data: females,
						backgroundColor: chartColors.warningLightColor,
						borderColor: 'transparent'
					},
					{
						label: 'Male',
						data: males,
						backgroundColor: chartColors.lineChartDanger,
						borderColor: 'transparent'
					}
				]
			},
			options: {
				elements: {
					rectangle: {
						borderWidth: 2,
						borderSkipped: 'bottom'
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				responsiveAnimationDuration: 500,
				legend: {
					display: false
				},
				tooltips: {
					// Updated default tooltip UI
					shadowOffsetX: 1,
					shadowOffsetY: 1,
					shadowBlur: 8,
					shadowColor: chartColors.tooltipShadow,
					backgroundColor: $themeColors.light,
					titleFontColor: $themeColors.dark,
					bodyFontColor: $themeColors.dark
				},
				scales: {
					xAxes: [
						{
							display: true,
							gridLines: {
								display: true,
								color: chartColors.grid_line_color,
								zeroLineColor: chartColors.grid_line_color
							},
							scaleLabel: {
								display: false
							},
							ticks: {
								fontColor: chartColors.labelColor
							}
						}
					],
					yAxes: [
						{
							display: true,
							gridLines: {
								color: chartColors.grid_line_color,
								zeroLineColor: chartColors.grid_line_color
							},
							ticks: {
								stepSize: stepY,
								min: 0,
								max: maxY,
								fontColor: chartColors.labelColor
							}
						}
					]
				}
			}
		};
	},

	horizontalBarChart: {
		options: {
			elements: {
				rectangle: {
					borderWidth: 2,
					borderSkipped: 'top'
				}
			},
			tooltips: {
				// Updated default tooltip UI
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				shadowBlur: 8,
				shadowColor: chartColors.tooltipShadow,
				backgroundColor: $themeColors.light,
				titleFontColor: $themeColors.dark,
				bodyFontColor: $themeColors.dark
			},
			responsive: true,
			maintainAspectRatio: false,
			responsiveAnimationDuration: 500,
			legend: {
				display: false
			},
			scales: {
				xAxes: [
					{
						display: true,
						gridLines: {
							zeroLineColor: chartColors.grid_line_color,
							borderColor: 'transparent',
							color: chartColors.grid_line_color,
							drawTicks: false
						},
						scaleLabel: {
							display: true
						},
						ticks: {
							min: 0,
							fontColor: chartColors.labelColor
						}
					}
				],
				yAxes: [
					{
						display: true,
						gridLines: {
							display: false
						},
						scaleLabel: {
							display: true
						},
						ticks: {
							fontColor: chartColors.labelColor
						}
					}
				]
			}
		},
		data: {
			labels: ['MON', 'TUE', 'WED ', 'THU', 'SAT', 'SUN'],
			datasets: [
				{
					data: [710, 350, 470, 580, 460, 120],
					backgroundColor: $themeColors.info,
					borderColor: 'transparent',
					barThickness: 15
				}
			]
		}
	},

	lineChart: {
		options: {
			responsive: true,
			maintainAspectRatio: false,
			backgroundColor: false,
			hover: {
				mode: 'label'
			},
			tooltips: {
				// Updated default tooltip UI
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				shadowBlur: 8,
				shadowColor: chartColors.tooltipShadow,
				backgroundColor: $themeColors.white,
				titleFontColor: $themeColors.black,
				bodyFontColor: $themeColors.black
			},
			layout: {
				padding: {
					top: -15,
					bottom: -25,
					left: -15
				}
			},
			scales: {
				xAxes: [
					{
						display: true,
						scaleLabel: {
							display: true
						},
						gridLines: {
							display: true,
							color: chartColors.grid_line_color,
							zeroLineColor: chartColors.grid_line_color
						},
						ticks: {
							fontColor: chartColors.labelColor
						}
					}
				],
				yAxes: [
					{
						display: true,
						scaleLabel: {
							display: true
						},
						ticks: {
							stepSize: 100,
							min: 0,
							max: 400,
							fontColor: chartColors.labelColor
						},
						gridLines: {
							display: true,
							color: chartColors.grid_line_color,
							zeroLineColor: chartColors.grid_line_color
						}
					}
				]
			},
			legend: {
				position: 'top',
				align: 'start',
				labels: {
					usePointStyle: true,
					padding: 25,
					boxWidth: 9
				}
			}
		},
		data: {
			labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
			datasets: [
				{
					data: [80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290, 360, 375],
					label: 'Europe',
					borderColor: chartColors.lineChartDanger,
					lineTension: 0.5,
					pointStyle: 'circle',
					backgroundColor: chartColors.lineChartDanger,
					fill: false,
					pointRadius: 1,
					pointHoverRadius: 5,
					pointHoverBorderWidth: 5,
					pointBorderColor: 'transparent',
					pointHoverBorderColor: $themeColors.white,
					pointHoverBackgroundColor: chartColors.lineChartDanger,
					pointShadowOffsetX: 1,
					pointShadowOffsetY: 1,
					pointShadowBlur: 5,
					pointShadowColor: chartColors.tooltipShadow
				},
				{
					data: [80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170, 210, 200, 280],
					label: 'Asia',
					borderColor: chartColors.lineChartPrimary,
					lineTension: 0.5,
					pointStyle: 'circle',
					backgroundColor: chartColors.lineChartPrimary,
					fill: false,
					pointRadius: 1,
					pointHoverRadius: 5,
					pointHoverBorderWidth: 5,
					pointBorderColor: 'transparent',
					pointHoverBorderColor: $themeColors.white,
					pointHoverBackgroundColor: chartColors.lineChartPrimary,
					pointShadowOffsetX: 1,
					pointShadowOffsetY: 1,
					pointShadowBlur: 5,
					pointShadowColor: chartColors.tooltipShadow
				},
				{
					data: [80, 99, 82, 90, 115, 115, 74, 75, 130, 155, 125, 90, 140, 130, 180],
					label: 'Africa',
					borderColor: chartColors.warningColorShade,
					lineTension: 0.5,
					pointStyle: 'circle',
					backgroundColor: chartColors.warningColorShade,
					fill: false,
					pointRadius: 1,
					pointHoverRadius: 5,
					pointHoverBorderWidth: 5,
					pointBorderColor: 'transparent',
					pointHoverBorderColor: $themeColors.white,
					pointHoverBackgroundColor: chartColors.warningColorShade,
					pointShadowOffsetX: 1,
					pointShadowOffsetY: 1,
					pointShadowBlur: 5,
					pointShadowColor: chartColors.tooltipShadow
				}
			]
		}
	},

	radarChart: {
		data: {
			labels: ['STA', 'STR', 'AGI', 'VIT', 'CHA', 'INT'],
			datasets: [
				{
					label: 'Donté Panlin',
					data: [25, 59, 90, 81, 60, 82],
					fill: true,
					backgroundColor: 'rgba(255,161,161, 0.9)',
					borderColor: 'transparent',
					pointBackgroundColor: 'transparent',
					pointBorderColor: 'transparent'
				},
				{
					label: 'Mireska Sunbreeze',
					data: [40, 100, 40, 90, 40, 90],
					fill: true,
					backgroundColor: 'rgba(155,136,250, 0.9)',
					borderColor: 'transparent',
					pointBackgroundColor: 'transparent',
					pointBorderColor: 'transparent'
				}
			]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			responsiveAnimationDuration: 500,
			legend: {
				position: 'top',
				labels: {
					padding: 25,
					fontColor: chartColors.labelColor
				}
			},
			tooltips: {
				enabled: false,
				custom(tooltip) {
					const tooltipEl = document.getElementById('tooltip');
					if (tooltip.body) {
						tooltipEl.style.display = 'block';
						if (tooltip.body[0].lines && tooltip.body[0].lines[0]) {
							// eslint-disable-next-line prefer-destructuring
							tooltipEl.innerHTML = tooltip.body[0].lines[0];
						}
					} else {
						setTimeout(() => {
							tooltipEl.style.display = 'none';
						}, 500);
					}
				}
			},
			gridLines: {
				display: false
			},
			scale: {
				ticks: {
					maxTicksLimit: 1,
					display: false,
					fontColor: chartColors.labelColor
				},
				gridLines: {
					color: chartColors.grid_line_color
				},
				angleLines: { color: chartColors.grid_line_color }
			}
		}
	},

	polarChart: {
		options: {
			responsive: true,
			maintainAspectRatio: false,
			responsiveAnimationDuration: 500,
			legend: {
				position: 'right',
				labels: {
					usePointStyle: true,
					padding: 25,
					boxWidth: 10,
					fontColor: chartColors.labelColor
				}
			},
			tooltips: {
				// Updated default tooltip UI
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				shadowBlur: 8,
				shadowColor: chartColors.tooltipShadow,
				backgroundColor: $themeColors.light,
				titleFontColor: $themeColors.dark,
				bodyFontColor: $themeColors.dark
			},
			scale: {
				scaleShowLine: true,
				scaleLineWidth: 1,
				ticks: {
					display: false,
					fontColor: chartColors.labelColor
				},
				reverse: false,
				gridLines: {
					display: false
				}
			},
			animation: {
				animateRotate: false
			}
		},
		data: {
			labels: ['Africa', 'Asia', 'Europe', 'America', 'Antarctica', 'Australia'],
			datasets: [
				{
					label: 'Population (millions)',
					backgroundColor: [
						chartColors.primaryColorShade,
						chartColors.warningColorShade,
						$themeColors.primary,
						chartColors.infoColorShade,
						chartColors.greyColor,
						chartColors.successColorShade
					],
					data: [19, 17.5, 15, 13.5, 11, 9],
					borderWidth: 0
				}
			]
		}
	},

	doughnutChart(data = [10, 62], labels = ['Male', 'Female']) {
		return {
			options: {
				responsive: true,
				maintainAspectRatio: false,
				responsiveAnimationDuration: 500,
				cutoutPercentage: 60,
				legend: { display: false },
				tooltips: {
					callbacks: {
						label(tooltipItem, data) {
							const label = data.datasets[0].labels[tooltipItem.index] || '';
							const value = data.datasets[0].data[tooltipItem.index];
							const total = data.datasets[0].data.reduce((prev, cur) => prev + cur, 0);
							const output = ` ${label} : ${value} (${((value / total) * 100).toFixed(1)}%)`;
							return output;
						}
					},
					// Updated default tooltip UI
					shadowOffsetX: 1,
					shadowOffsetY: 1,
					shadowBlur: 8,
					shadowColor: chartColors.tooltipShadow,
					backgroundColor: $themeColors.light,
					titleFontColor: $themeColors.dark,
					bodyFontColor: $themeColors.dark
				}
			},
			data: {
				datasets: [
					{
						labels,
						data,
						backgroundColor: [chartColors.lineChartDanger, chartColors.warningLightColor],
						borderWidth: 0,
						pointStyle: 'rectRounded'
					}
				]
			}
		};
	}
};
