<template>
	<div>
		<b-tabs pills lazy>
			<!-- <b-tab active>
				<template #title>
					<span class="font-weight-bold">مخرجات الدورة التدريبية</span>
				</template>
				<Trainning />
			</b-tab>

			<b-tab>
				<template #title>
					<span class="font-weight-bold">مخرجات التقييم</span>
				</template>
				<Evaluation />
			</b-tab> -->

			<b-tab>
				<template #title>
					<span class="font-weight-bold">مخرجات العمل الميداني</span>
				</template>
				<FieldWork />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';

// import Trainning from './trainning/DataTable';
// import Evaluation from './evaluation/DataTable';
import FieldWork from './fieldwork/DataTable';

export default {
	name: 'Gender',

	components: {
		BTabs,
		BTab,

		// Trainning,
		// Evaluation,
		FieldWork
	}
};
</script>

<style lang="scss" scoped></style>
